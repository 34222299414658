const LearnMore = () => {
    return(
        <div className='rickyPage'>
            <br></br>
            <br></br>
            <img src='https://media1.tenor.com/images/467d353f7e2d43563ce13fddbb213709/tenor.gif?itemid=12136175' alt='Rick Astley.gif'></img>
            <br></br>
            <br></br>
            <section className='ricky'>You weren't supposed to click this. I haven't developed this section yet.</section>
        </div>
    )


}

export default LearnMore